export const userRole = state => {
  return state.userRole
}

export const manageAdminBoard = state => {
  return state.manageAdminBoard
}

export const manageSuperAdminBoard = state => {
  return state.manageSuperAdminBoard
}

export const managePartnerAdminBoard = state => {
  return state.managePartnerAdminBoard
}

export const manageUserSettings = state => {
  return state.userRole !== 'Viewer'
}

export const canPurchase = state => {
  return state.canPurchase
}

export const editClientGroup = state => {
  return state.editClientGroup
}

export const isReportWriterEnabled = state => {
  return state.enabledModules.some(module => module.name === 'Report Writer')
}

export const enabledPaths = state => {
  return state.enabledPaths
}
export const uiModules = state => {
  return state.uiModules
}
export const canVendorFit = state => {
  return state.enabledPaths.includes(
    '/fi_prospect/vendor_fit/select_institution'
  )
}
export const canShowDigitalChannels = state => {
  return state.enabledPaths.includes('/digital_channels/dashboard')
}
export const isProfilePartiallyEnabled = state => {
  const analyticsModule = state.enabledModules.find(
    module =>
      module.name === 'FI Analytics' || module.name === 'Institution Analytics'
  )
  if (
    analyticsModule &&
    analyticsModule.children.find(
      child => child.name === 'FIN Profile' && child.partially_enabled === true
    )
  )
    return true
  else return false
}
export const isDigitalChannelsAvailable = state => {
  return (
    state.enterprisePermissions && state.enterprisePermissions.digital_channel
  )
}
export const isFIContactsAvailable = state => {
  return (
    state.enterprisePermissions && state.enterprisePermissions.contact_details
  )
}
export const isFinQueryAvailable = state => {
  return (
    state.enterprisePermissions && state.enterprisePermissions.prospect_list
  )
}
export const isMarketReportsAvailable = state => {
  return (
    state.enterprisePermissions && state.enterprisePermissions.market_reports
  )
}
export const isAnalyticsEnterpriseUser = state => {
  return state.enterprisePermissions && state.enterprisePermissions.fi_analytics
}
export const isFinApiEnterpriseUser = state => {
  return state.enterprisePermissions && state.enterprisePermissions.fin_api
}

export const isMbcAvailable = state => {
  return state.enterprisePermissions && state.enterprisePermissions.mbc
}

export const showTechnographics = state => {
  return state.purchasedModules && state.purchasedModules.fin_technographics
}

export const showFinQuery = state => {
  return state.purchasedModules && state.purchasedModules.fin_query
}
export const showCrm = state => {
  return state.purchasedModules && state.purchasedModules.fin_crm
}
export const showVendorFit = state => {
  return state.purchasedModules && state.purchasedModules.fin_vendorfit
}
export const showAdvisor = state => {
  return state.purchasedModules && state.purchasedModules.fin_advisor
}
export const showReporter = state => {
  return state.purchasedModules && state.purchasedModules.fin_reporter
}
export const showProfile = state => {
  return state.purchasedModules && state.purchasedModules.fin_profile
}
export const showMarketReports = state => {
  return state.purchasedModules && state.purchasedModules.fintech_market_reports
}
export const showDigitalChannels = state => {
  return state.purchasedModules && state.purchasedModules.fin_digital_channels
}
export const showIndustryReporter = state => {
  return state.purchasedModules && state.purchasedModules.fin_industry_reporter
}

export const canAccessImportTools = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  return (
    ['FIN Admin', 'Client Admin', 'User'].includes(state.userRole) &&
    !rootGetters['Authentication/isFiMenu']
  )
}
export const isActiveClientPricingAvailable = state => {
  return state.activeClientPricingAvailable
}
export const currentUserGuide = state => {
  return state.currentUserGuide
}
