export default {
  path: '/super_admin',
  redirect: '/super_admin/dashboard',
  name: 'SuperAdmin',
  meta: { requiredAuth: true },
  component: () => import('./pages/super_admin.js'),
  children: [
    {
      path: 'dashboard',
      name: 'SuperAdminDashboard',
      component: () => import('./pages/Dashboard'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'all_users',
      name: 'AllUsers',
      component: () => import('./pages/AllUsers'),
      meta: { type: 'super_admin' }
    },
    {
      path: '/super_admin/user/:id/edit',
      name: 'SuperAdminEditUser',
      component: () => import('./pages/EditUser'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'profile_cards',
      name: 'ProfileCards',
      component: () => import('./pages/ProfileCards'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'all_groups',
      name: 'AllGroups',
      component: () => import('./pages/AllGroups'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'rerun_reports',
      name: 'RerunReports',
      component: () => import('./pages/RerunReports'),
      meta: { type: 'super_admin' }
    },
    // {
    //   path: 'tableau',
    //   name: 'Tableau',
    //   component: () => import('./pages/Tableau'),
    //   meta: { type: 'super_admin' }
    // },
    {
      path: 'app_configs',
      name: 'AppConfigs',
      component: () => import('./pages/AppConfigs'),
      meta: { type: 'super_admin' }
    },
    {
      path: '/super_admin/profile_card/:id/edit',
      name: 'SuperAdminEditCard',
      component: () => import('./pages/EditProfileCard'),
      meta: { type: 'super_admin' }
    },
    {
      path: '/super_admin/profile_cards/new',
      name: 'SuperAdminCreateCard',
      component: () => import('./pages/CreateProfileCard'),
      meta: { type: 'super_admin' }
    },
    {
      path: '/super_admin/groups/new',
      name: 'SuperAdminCreateGroup',
      component: () => import('./pages/CreateGroup'),
      meta: { type: 'super_admin' }
    },
    {
      path: '/super_admin/group/:id/edit',
      name: 'SuperAdminEditGroup',
      component: () => import('./pages/EditGroup'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'client_pricing_schedules',
      name: 'ClientPricingSchedules',
      component: () => import('./pages/ClientPricingSchedules'),
      meta: { type: 'admin' }
    },
    {
      path: 'client_pricing_schedules/:id',
      name: 'ClientPricingSchedule',
      component: () => import('./pages/ClientPricingSchedule'),
      meta: { type: 'admin' }
    },
    {
      path: '/super_admin/actions',
      name: 'SuperAdminActions',
      component: () => import('./pages/Actions'),
      meta: { type: 'super_admin' }
    },
    {
      path: '/super_admin/features',
      name: 'Features',
      component: () => import('./pages/Features'),
      meta: { type: 'super_admin' }
    },
    {
      path: '/super_admin/report_access/accessible_reports',
      name: 'AccessibleReports',
      component: () => import('./pages/report_access/AccessibleReports'),
      meta: { type: 'super_admin' }
    },
    {
      path: '/super_admin/report_access/select_fi',
      name: 'SelectReportFI',
      component: () => import('./pages/report_access/SelectFI'),
      meta: { type: 'super_admin' }
    },
    {
      path: '/super_admin/upload_logo',
      name: 'LogoUpload',
      component: () => import('./pages/logo_upload/Home'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'user_activity_report',
      name: 'UserActivityReport',
      component: () => import('./pages/UserActivityReport'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'user_orders_report',
      name: 'SuperAdminUserPurchaseHistory',
      component: () => import('./pages/UserPurchaseHistory'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'upload_user_guides',
      name: 'SuperAdminUploadUserGuides',
      component: () => import('./pages/UploadUserGuides'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'user_access_request_report',
      name: 'UserAccessRequestReport',
      component: () => import('./pages/UserAccessRequestReport'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'large_downloads_report',
      name: 'LargeDownloadsReport',
      component: () => import('./pages/LargeDownloadsReport'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'client_activity_reports',
      name: 'ClientActivityReport',
      component: () => import('./pages/ClientActivityReports'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'update_fi_name',
      name: 'UpdateFiName',
      component: () => import('./pages/UpdateFiName'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'authorization',
      name: 'Authorization',
      component: () => import('./pages/authorization/Home'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'navigator_groups',
      name: 'SuperAdminNavigatorGroups',
      component: () => import('./pages/NavigatorGroups'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'navigator_reports',
      name: 'SuperAdminNavigatorReports',
      component: () => import('./pages/NavigatorReports'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'manage_subscrptions',
      name: 'SuperAdminManageSubscrptions',
      component: () => import('./pages/ManageSubscrptions'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'segmentations',
      name: 'SuperAdminSegmentations',
      component: () => import('./pages/Segmentations'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'mock_users',
      name: 'MockUsers',
      component: () => import('./pages/MockUsers'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'technographics/performographics',
      name: 'performographics',
      component: () => import('@/views/performographics/index'),
      meta: {
        type: 'super_admin'
      }
    },
    {
      path: 'technographics/performographics/report',
      name: 'performographics_report',
      component: () => import('@/views/performographics/report'),
      meta: {
        type: 'super_admin'
      }
    },
    {
      path: 'strategy/about',
      name: 'AboutStrategy',
      component: () => import('@/modules/core/pages/AboutStrategy'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'user_edits',
      name: 'UserEdits',
      component: () => import('./pages/online_edits/UserEdits'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'copy_reports',
      name: 'CopyReports',
      component: () => import('./pages/CopyReports'),
      meta: { type: 'super_admin' }
    },
    {
      path: 'copy_queries',
      name: 'CopyQueries',
      component: () => import('./pages/CopyQueries'),
      meta: { type: 'super_admin' }
    }
  ]
}
