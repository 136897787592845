import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'
import * as statusApi from '@/modules/core/api/status'

import advisorRoutes from '@/modules/advisor/router'
import reporterRoutes from '@/modules/reporter/router'
import profileRoutes from '@/modules/profile/router'
import marketReportRoutes from '@/modules/market_reports/router'
import prospectRoutes from '@/modules/prospects/router'
import strategyRoutes from '@/modules/strategy/router'
import reportWriterRoutes from '@/modules/report_writer/router'
import adminRoutes from '@/modules/admin/router'
import superAdminRoutes from '@/modules/super_admin/router'
import partnerAdminRoutes from '@/modules/partner_admin/router'
import authRoutes from '@/modules/authentication/router'
import industryReporterRoutes from '@/modules/industry_reporter/router'
import userRoutes from '@/modules/user/router'
import vendorFitRoutes from '@/modules/vendor_fit/router'
import digitalChannelsRoutes from '@/modules/digital_channels/router'

// Containers
import Full from '@/containers/Full'

// Views - Pages
import Page404 from '@/views/pages/Page404'
import Page403 from '@/views/pages/Page403'
import Dashboard from '@/views/pages/Dashboard'

// View - IFrames
import FinAppsSearch from '@/views/IFrames/Search'

// api
import userAPI from '@/api/finapps/user'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: Full,
      children: [
        {
          path: '/fi_vertical_strategy/about_digital_channels',
          name: 'DigitalChannelsDashboard',
          component: () => import('@/modules/core/pages/AboutDigitalChannels'),
          meta: {
            type: 'digital_channels'
          }
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: { requiredAuth: true }
        },
        {
          path: '/technographics/about',
          name: 'AboutProspectList',
          component: () => import('@/modules/core/pages/AboutProspectList'),
          meta: { type: 'prospect_list', modulePath: '/technographics/about' }
        },
        {
          path: '/analytics/analytics/about',
          name: 'AboutAnalytics',
          component: () => import('@/modules/core/pages/AboutAnalytics'),
          meta: { type: 'analytics', modulePath: '/analytics/analytics/about' }
        },
        {
          path: '/institution_analytics/about',
          name: 'AboutInstitutionAnalytics',
          component: () =>
            import('@/modules/core/pages/AboutInstitutionAnalytics'),
          meta: {
            type: 'institution_analytics',
            modulePath: '/institution_analytics/about'
          }
        },
        {
          path: '/technology_analytics/about',
          name: 'AboutTechnologyAnalytics',
          component: () =>
            import('@/modules/core/pages/AboutTechnologyAnalytics'),
          meta: {
            type: 'technology_analytics',
            modulePath: '/technology_analytics/about'
          }
        },
        {
          path: '/reports/about',
          name: 'AboutMarketReports',
          component: () => import('@/modules/core/pages/AboutMarketReports'),
          meta: { type: 'market_reports', modulePath: '/reports/about' }
        },
        {
          path: '/fi_vertical_strategy/about_market_reports',
          name: 'AboutFinTechMarketReports',
          component: () =>
            import('@/modules/core/pages/AboutFinTechMarketReports'),
          meta: {}
        },
        {
          path: '/fi_vertical_strategy/about_industry_reporter',
          name: 'AboutIndustryReporter',
          component: () => import('@/modules/core/pages/AboutIndustryReporter'),
          meta: {}
        },
        {
          path: '/analytics/about_advisor',
          name: 'AboutAdvisor',
          component: () => import('@/modules/core/pages/AboutAdvisor'),
          meta: {
            type: 'advisor'
          }
        },
        {
          path: '/analytics/about_reporter',
          name: 'AboutReporter',
          component: () => import('@/modules/core/pages/AboutReporter'),
          meta: {
            type: 'reporter'
          }
        },
        {
          path: '/analytics/about_profile',
          name: 'AboutProfile',
          component: () => import('@/modules/core/pages/AboutProfile'),
          meta: {
            type: 'profile'
          }
        },
        {
          path: '/fi_prospect/about_technographics',
          name: 'AboutTechnographics',
          component: () => import('@/modules/core/pages/AboutTechnographics'),
          meta: {
            type: 'technographics'
          }
        },
        {
          path: '/fi_prospect/about_fin_query',
          name: 'AboutFinQuery',
          component: () => import('@/modules/core/pages/AboutFinQuery'),
          meta: {
            type: 'advanced_query'
          }
        },
        {
          path: '/fi_prospect/about_vendor_fit',
          name: 'AboutVendorFit',
          component: () => import('@/modules/core/pages/AboutVendorFit'),
          meta: {
            type: 'vendor_fit'
          }
        },
        {
          path: '/technographics/fin_query/about_crm',
          name: 'AboutCRMDataEnrichment',
          component: () =>
            import('@/modules/core/pages/AboutCRMDataEnrichment'),
          meta: { type: 'crm_data_enrichment' }
        },
        prospectRoutes,
        marketReportRoutes,
        advisorRoutes,
        reporterRoutes,
        profileRoutes,
        strategyRoutes,
        reportWriterRoutes,
        adminRoutes,
        superAdminRoutes,
        partnerAdminRoutes,
        industryReporterRoutes,
        userRoutes,
        vendorFitRoutes,
        digitalChannelsRoutes
      ]
    },
    ...authRoutes,
    {
      path: '/redirect',
      name: 'Redirect',
      component: () => import('@/modules/core/pages/Redirect')
    },
    {
      path: '/invite_register',
      name: 'InviteRegister',
      meta: { requiredAuth: false },
      component: () => import('@/modules/admin/pages/InviteRegister')
    },
    {
      path: '*',
      name: 'page-not-found',
      component: Page404
    },
    {
      path: '/unauthorized',
      name: 'page403',
      component: Page403
    },
    {
      path: '/fin_apps_search',
      name: 'fin-apps-search',
      component: FinAppsSearch,
      meta: { defaultAuth: true }
    },
    {
      path: '/analytics/old_site',
      beforeEnter() {
        window.location = 'http://analytics.finavigator.com'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // for users from iframe
  // guest user access is revoked, so removig iframe support
  // if (to.meta.defaultAuth) {
  //   store.dispatch('Authentication/setDefaultCompanyType')
  //   store.commit('setFinancialInstitutionBoolean')
  // }

  store.commit('setAside', false)

  if (store.getters['Authentication/authorizedUser']) {
    if (to.meta.modulePath) {
      if (store.getters['Ability/enabledPaths'].includes(to.meta.modulePath)) {
        next()
      } else {
        handlePageAccess(to, from, next)
      }
    } else {
      next()
    }
  } else if (
    to.path === '/sign_in' ||
    to.path === '/sign_up' ||
    to.name === 'new_password' ||
    to.path === '/invite_register' ||
    to.path === '/verify_email' ||
    to.path === '/redirect' ||
    to.path === '/terms_of_service'
  ) {
    // if (window.location.host === process.env.VUE_APP_ETHOS_URL_NAME) {
    //   next('/sso?timeout=true')
    // } else {
    next()
    // }
  } else if (to.path === '/sso') {
    next()
  } else {
    store.dispatch('setRedirectUrl', to.fullPath)
    next('/sign_in')
  }
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    statusApi.latestRevision().then(revision => {
      let clientRevision = store.getters.latestRevision
      if (revision && clientRevision !== revision) {
        store.commit('setLatestRevision', revision)
        store.commit('setRevisionOutdated', true)
      }
    })
  }
})

const handlePageAccess = (to, from, next) => {
  userAPI.enabledModules().then(res => {
    let enabledModules = res.ui_modules.map(um => um.path)

    if (enabledModules.includes(to.meta.modulePath)) {
      store.commit('Ability/setEnabledPaths', res.ui_modules, {
        root: true
      })
      store.commit('Ability/setEnabledModules', res.ui_modules, {
        root: true
      })
      next()
    } else {
      Vue.toasted.show('Page access restricted, please contact admin', {
        icon: 'user-times',
        type: 'error'
      })
      next('/')
    }
  })
}

export default router
