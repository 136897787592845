<template>
  <!-- FI Home Page -->
  <PageCard
    pageName="FIN Apps"
    :iconClass="['fa', 'fa-home', 'fa-lg']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div
        :class="
          partnerSite ? ['homepage-content partner-site'] : ['homepage-content']
        "
      >
        <b-row class="container-margin">
          <b-col>
            <div class="text-center">
              <h1 class="mb-4 borderless-banner-title">
                One Platform to Inform Resourcing Priorities and Strategy
                Development
              </h1>
            </div>
            <div class="my-3 text-center banner-desc">
              <p>
                Identify and align on your top opportunities to improve profit,
                growth, risk, &amp; customer offering/technologies
              </p>
            </div>
            <div></div>
          </b-col>
        </b-row>
        <section class="section path-section">
          <div class="">
            <div class="row container-margin">
              <div class="col-md mb-3 px-2">
                <AnalyticsCard
                  title="Institution Analytics"
                  :image="imgFiIa"
                  description="The Institution Analytics solutions cover performance,
                      offering and technologies allowing users to easily query
                      unprecedented data, generate consultative assessments or
                      customize reporting on specific institutions or industry
                      segments."
                  button1Text="See Solutions"
                  button1Link="/institution_analytics/about"
                  videoBtnLink=""
                  :index="0"
                  @showModal="showModal('IA')"
                >
                </AnalyticsCard>
              </div>

              <div class="col-md mb-3 px-2">
                <AnalyticsCard
                  title="Technology Analytics"
                  :image="imgFiTa"
                  description="The Technology Analytics solutions uniquely position users
                      to assess technologies driving digital transformation
                      through vendor market share &amp; client firmographics.
                      Additionally, users can harness big data to identify their
                      best vendor solution fits through regression analysis."
                  button1Text="See Solutions"
                  button1Link="/technology_analytics/about"
                  videoBtnLink=""
                  :index="1"
                  @showModal="showModal('TA')"
                >
                </AnalyticsCard>
              </div>
            </div>
          </div>
        </section>

        <br />
        <FiUseCaseDetails></FiUseCaseDetails>
        <b-modal
          id="videoModal"
          size="lg"
          :hide-footer="true"
          @ok="show = false"
          title=""
        >
          <div v-html="video" v-show="show"></div>
        </b-modal>
      </div>
    </template>
  </PageCard>
</template>

<script>
import { mapState } from 'vuex'
import PageCard from '@/modules/core/components/layouts/PageCard'
import AnalyticsCard from '@/modules/core/components/layouts/AnalyticsCard'
import FiUseCaseDetails from '@/modules/core/pages/FiUseCaseDetails.vue'
import imgFiIa from '../../../static/img/FI_IA.png'
import imgFiTa from '../../../static/img/FI_TA.png'
export default {
  name: 'AboutDashboard',
  components: {
    PageCard,
    AnalyticsCard,
    FiUseCaseDetails
  },
  data() {
    return {
      imgFiIa: imgFiIa,
      imgFiTa: imgFiTa,
      show: false,
      video: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1036628717?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Institution Analytics"></iframe></div>`,
      videoIA: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1036628717?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Institution Analytics"></iframe></div>`,
      videoTA: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1036628754?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Technology Analytics"></iframe></div>`
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  },
  methods: {
    showModal(code) {
      this.video = code === 'IA' ? this.videoIA : this.videoTA
      this.show = true
      this.$bvModal.show('videoModal')
    }
  }
}
</script>
<style lang="scss" src="../../modules/core/scss/infoCards.scss"></style>
