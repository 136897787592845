export default {
  navItems: [
    {
      name: 'Home',
      url: '/',
      icon: 'icon-home',
      children: [],
      type: 'home',
      enableFor: 'fi'
    },
    {
      name: 'FI Prospect List',
      url: '/technographics/about',
      icon: 'cui-target',
      type: 'title',
      children: [
        {
          name: 'FIN Technographics',
          url: '/technographics/product/search',
          type: 'page',
          icon: 'cui-terminal',
          class: 'sub-nav'
        },
        {
          name: 'FIN Performographics',
          url: '/technographics/performographics',
          type: 'page',
          icon: 'cui-gem',
          class: 'sub-nav'
        },
        {
          name: 'FIN Query',
          url: '/technographics/fin_query/query',
          type: 'page',
          icon: 'cui-filter',
          class: 'sub-nav'
        }
      ]
    },
    {
      name: 'FI Analytics',
      url: '/analytics/analytics/about',
      icon: 'cui-chart-line',
      type: 'title',
      enableFor: 'fi',
      children: [
        {
          name: 'FIN Advisor',
          url: '/analytics/advisor/select_institution',
          type: 'sub-title',
          enableFor: 'fi',
          icon: 'cui-sitemap',
          class: 'sub-nav'
        },
        {
          name: 'FIN Reporter',
          url: '/analytics/reporter/select_institution',
          type: 'sub-title',
          enableFor: 'fi',
          icon: 'cui-spreadsheet',
          class: 'sub-nav'
        },
        {
          name: 'FIN Profile',
          url: '/analytics/fin_profile/select_institution',
          type: 'sub-title',
          icon: 'cui-dashboard',
          class: 'sub-nav'
        }
      ]
    },
    {
      name: 'FI Vertical Strategy',
      url: '/reports/about',
      icon: 'cui-file-code',
      type: 'title',
      children: [
        {
          name: 'FinTech Market Reports',
          url: '/reports/market_reports',
          type: 'page',
          icon: 'cui-chart-pie',
          class: 'sub-nav'
        },
        {
          name: 'FinTech Firmographics',
          url: '/reports/firmographics',
          type: 'page',
          icon: 'cui-building',
          class: 'sub-nav'
        },
        {
          name: 'FIN Digital Channels',
          url: '/digital_channels/dashboard',
          type: 'page',
          icon: 'icon-screen-smartphone',
          class: 'sub-nav'
        },
        {
          name: 'FIN Industry Reporter',
          url: '/reports/industry_reporter/fi_group',
          type: 'sub-title',
          icon: 'cui-industry',
          class: 'sub-nav'
        }
      ]
    },
    {
      name: 'Fintech Strategy',
      url: '/strategy/about',
      icon: 'cui-map',
      type: 'title',
      enableFor: 'fi',
      children: [
        {
          name: 'FI Segmentation',
          url: '/strategy/segmentation',
          type: 'page',
          icon: 'cui-move',
          class: 'sub-nav'
        },
        {
          name: 'White Space Assessment',
          url: '/strategy/white_space_assessment',
          type: 'page',
          icon: 'cui-puzzle-piece',
          class: 'sub-nav'
        },
        {
          name: 'FIN Publications',
          url: '/strategy/publications',
          type: 'page',
          enableFor: 'fi',
          icon: 'cui-document',
          class: 'sub-nav'
        }
      ]
    },
    {
      name: 'Crm Data Enrichment',
      url: '/crm_data/about',
      icon: 'icon-layers',
      type: 'title',
      children: []
    }
  ],
  subNavItems: [
    {
      name: 'FIN Reporter',
      url: '/analytics/reporter/select_institution',
      icon: 'cui-spreadsheet',
      type: 'title',
      children: [
        {
          name: 'Select Institution',
          url: '/analytics/reporter/select_institution',
          type: 'page',
          class: 'sub-nav-child',
          icon: ''
        },
        {
          name: 'Select Peer Group',
          url: '/analytics/reporter/select_peer_group',
          type: 'page',
          class: 'sub-nav-child'
        },
        {
          name: 'Select Report',
          url: '/analytics/reporter/select_report',
          type: 'page',
          class: 'sub-nav-child'
        },
        {
          name: 'Report View',
          url: '/analytics/reporter/metrics',
          type: 'page',
          class: 'sub-nav-child'
        }
      ]
    },
    {
      name: 'FIN Profile',
      url: '/analytics/fin_profile/select_institution',
      icon: 'cui-dashboard',
      type: 'title',
      children: [
        {
          name: 'Select Institution',
          url: '/analytics/fin_profile/select_institution',
          type: 'page',
          class: 'sub-nav-child'
        },
        {
          name: 'Report View',
          url: '/analytics/fin_profile/custom_profile',
          type: 'page',
          class: 'sub-nav-child'
        }
      ]
    },
    {
      name: 'FIN VendorFit',
      url: '/fi_prospect/vendor_fit/select_institution',
      icon: 'cui-puzzle-piece',
      type: 'title',
      children: [
        {
          name: 'Select Institution',
          url: '/fi_prospect/vendor_fit/select_institution',
          type: 'page',
          class: 'sub-nav-child'
        },
        {
          name: 'Predictions',
          url: '/fi_prospect/vendor_fit/predictions',
          type: 'page',
          class: 'sub-nav-child'
        }
      ]
    },
    {
      name: 'FIN Query',
      url: '/technographics/fin_query/query',
      icon: 'cui-filter',
      type: 'title',
      children: [
        {
          name: 'Query',
          url: '/technographics/fin_query/query',
          type: 'page',
          icon: 'fa fa-university',
          class: 'sub-nav-child pl-4'
        },
        {
          name: 'Report',
          url: '/technographics/fin_query/report',
          type: 'page',
          icon: 'fa fa-th',
          class: 'sub-nav-child pl-4'
        },
        {
          name: 'Download',
          url: '/technographics/fin_query/download',
          type: 'page',
          icon: 'fa fa-download',
          class: 'sub-nav-child pl-4'
        },
        {
          name: 'Conference Import',
          url: '/technographics/fin_query/conference_import',
          type: 'page',
          icon: 'fa fa-upload',
          class: 'sub-nav-child pl-4'
        },
        {
          name: 'FIN CRM',
          url: '/technographics/fin_query/crm_data',
          type: 'page',
          icon: 'icon-layers',
          class: 'sub-nav-child pl-4'
        }
      ]
    },
    {
      name: 'FIN Advisor',
      url: '/analytics/advisor/select_institution',
      icon: 'cui-sitemap',
      type: 'title',
      children: [
        {
          name: 'Select Institution',
          url: '/analytics/advisor/select_institution',
          type: 'page',
          class: 'sub-nav-child'
        },
        {
          name: 'Select Peer Group',
          url: '/analytics/advisor/select_peer_group',
          type: 'page',
          class: 'sub-nav-child'
        },
        {
          name: 'Profit',
          url: '/analytics/advisor/profit/summary',
          type: 'page',
          children: [
            {
              name: 'Executive Brief',
              url: '/analytics/advisor/profit/summary',
              type: '',
              class: 'sub-nav-child'
            },
            {
              name: 'Macro-Profitability',
              url: '/analytics/advisor/profit/macro_profitability',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Efficiency Ratio',
              url: '/analytics/advisor/profit/efficiency_ratio',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Net-Interest Margin',
              url: '/analytics/advisor/profit/net_interest_margin',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Non-Interest Income',
              url: '/analytics/advisor/profit/non_interest_income',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Non-Interest Expense',
              url: '/analytics/advisor/profit/non_interest_expense',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            }
          ]
        },
        {
          name: 'Growth',
          url: '/analytics/advisor/growth/summary',
          type: 'page',
          children: [
            {
              name: 'Executive Brief',
              url: '/analytics/advisor/growth/summary',
              type: '',
              class: 'sub-nav-child'
            },
            {
              name: 'Franchise Growth',
              url: '/analytics/advisor/growth/franchise_growth',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Loan Growth',
              url: '/analytics/advisor/growth/loan_growth',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Deposit Growth',
              url: '/analytics/advisor/growth/deposit_growth',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Earnings Growth',
              url: '/analytics/advisor/growth/earnings_growth',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            }
          ]
        },
        {
          name: 'Risk',
          url: '/analytics/advisor/risk/summary',
          type: 'page',
          children: [
            {
              name: 'Executive Brief',
              url: '/analytics/advisor/risk/summary',
              type: '',
              class: 'sub-nav-child'
            },
            {
              name: 'Enterprise Risk',
              url: '/analytics/advisor/risk/enterprise_risk',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Capital Adequacy',
              url: '/analytics/advisor/risk/capital_adequacy',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Asset Quality',
              url: '/analytics/advisor/risk/asset_quality',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Liquidity Risk',
              url: '/analytics/advisor/risk/liquidity_risk',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            },
            {
              name: 'Earnings Quality',
              url: '/analytics/advisor/risk/earnings_quality',
              type: 'advisor-graph',
              class: 'sub-nav-child'
            }
          ]
        },
        {
          name: 'Offering',
          url: '/analytics/advisor/offering/summary',
          type: 'page',
          children: [
            {
              name: 'Executive Brief',
              url: '/analytics/advisor/offering/summary',
              type: '',
              class: 'sub-nav-child'
            },
            {
              name: 'Retail Services',
              url: '/analytics/advisor/offering/retail_services',
              type: '',
              class: 'sub-nav-child'
            },
            {
              name: 'Business Services',
              url: '/analytics/advisor/offering/business_services',
              type: '',
              class: 'sub-nav-child'
            },
            {
              name: 'Vendors & Products',
              url: '/analytics/advisor/offering/vendor_products',
              type: '',
              class: 'sub-nav-child'
            },
            {
              name: 'Top Technologies',
              url: '/analytics/advisor/offering/top_technologies',
              type: '',
              class: 'sub-nav-child'
            }
          ]
        },
        {
          name: 'Key Metrics',
          url: '/analytics/advisor/profit/summary',
          type: 'page',
          class: 'sub-nav-child',
          children: []
        }
      ]
    },
    {
      name: 'FIN Industry Reporter',
      url: '/reports/industry_reporter/select_fi_group',
      icon: 'cui-industry',
      type: 'title',
      children: [
        {
          name: 'Select FI Group',
          url: '/reports/industry_reporter/select_fi_group',
          type: 'page',
          icon: '',
          class: 'sub-nav-child'
        },
        {
          name: 'Select Segmentation',
          url: '/reports/industry_reporter/segmentation',
          type: 'page',
          icon: '',
          class: 'sub-nav-child'
        },
        {
          name: 'Select Report',
          url: '/reports/industry_reporter/select_report',
          type: 'page',
          icon: '',
          class: 'sub-nav-child'
        },
        {
          name: 'View Report',
          url: '/reports/industry_reporter/report',
          type: 'page',
          icon: '',
          class: 'sub-nav-child'
        }
      ]
    }
  ],
  nestedNavItems: [
    'FI Analytics',
    'FIN Profile',
    'FIN Query',
    'FIN VendorFit',
    'FIN Industry Reporter'
  ],
  advisorCUMenu: [
    {
      name: 'Executive Brief',
      url: '/analytics/advisor/growth/summary',
      type: '',
      class: 'sub-nav-child'
    },
    {
      name: 'Member Growth',
      url: '/analytics/advisor/growth/member_growth',
      type: 'advisor-graph',
      class: 'sub-nav-child'
    },
    {
      name: 'Loan Growth',
      url: '/analytics/advisor/growth/loan_growth',
      type: 'advisor-graph',
      class: 'sub-nav-child'
    },
    {
      name: 'Share Growth',
      url: '/analytics/advisor/growth/share_growth',
      type: 'advisor-graph',
      class: 'sub-nav-child'
    },
    {
      name: 'Earnings Growth',
      url: '/analytics/advisor/growth/earnings_growth',
      type: 'advisor-graph',
      class: 'sub-nav-child'
    }
  ],
  advisorBankMenu: [
    {
      name: 'Executive Brief',
      url: '/analytics/advisor/growth/summary',
      type: '',
      class: 'sub-nav-child'
    },
    {
      name: 'Franchise Growth',
      url: '/analytics/advisor/growth/franchise_growth',
      type: 'advisor-graph',
      class: 'sub-nav-child'
    },
    {
      name: 'Loan Growth',
      url: '/analytics/advisor/growth/loan_growth',
      type: 'advisor-graph',
      class: 'sub-nav-child'
    },
    {
      name: 'Deposit Growth',
      url: '/analytics/advisor/growth/deposit_growth',
      type: 'advisor-graph',
      class: 'sub-nav-child'
    },
    {
      name: 'Earnings Growth',
      url: '/analytics/advisor/growth/earnings_growth',
      type: 'advisor-graph',
      class: 'sub-nav-child'
    }
  ]
}
