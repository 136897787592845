<template>
  <!-- VC Home Page -->
  <PageCard
    pageName="FIN Apps"
    :iconClass="['fa', 'fa-home', 'fa-lg']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div
        :class="
          partnerSite ? ['homepage-content partner-site'] : ['homepage-content']
        "
      >
        <b-row class="container-margin">
          <b-col>
            <div class="text-center">
              <h1 class="mb-4 borderless-banner-title">
                One Platform to Inform your B2Bank Prospecting, Sales Engagement
                & Strategy Development
              </h1>
            </div>
            <div class="my-3 text-center banner-desc">
              <p>
                Identify and align on your FI prospects, engage credibly as an
                advisor and drive revenue growth
              </p>
            </div>
            <div></div>
          </b-col>
        </b-row>
        <section class="section path-section">
          <div class="">
            <div class="row container-margin">
              <div class="col-md mb-3 px-2">
                <AnalyticsCard
                  title="FI Prospecting & CRM"
                  :image="imgVcPC"
                  description="The FI Prospecting & CRM solutions easily generate higher-probability FI prospects and enrich your CRM system’s account and contact data."
                  button1Text="See Solutions"
                  button1Link="/technographics/about"
                  videoBtnLink=""
                  @showModal="showModal('PC')"
                >
                </AnalyticsCard>
              </div>
              <div class="col-md mb-3 px-2">
                <AnalyticsCard
                  title="FI Analytics"
                  :image="imgVcA"
                  description="FI Analytics transform your financial institution relationships from vendor to advisor with unparalleled analytics to fit any meeting objective."
                  button1Text="See Solutions"
                  button1Link="/analytics/analytics/about"
                  videoBtnLink=""
                  @showModal="showModal('A')"
                >
                </AnalyticsCard>
              </div>
              <div class="col-md mb-3 px-2">
                <AnalyticsCard
                  title="FI Vertical Strategy"
                  :image="imgVcVS"
                  description="FI Vertical Strategy solutions augment strategy development leveraging unrivaled industry data on institutions & their vendor partners."
                  button1Text="See Solutions"
                  button1Link="/reports/about"
                  videoBtnLink=""
                  @showModal="showModal('VS')"
                >
                </AnalyticsCard>
              </div>
            </div>
          </div>
        </section>
        <UseCaseDetails></UseCaseDetails>
        <b-modal
          id="videoModal"
          size="lg"
          :hide-footer="true"
          @ok="show = false"
          title=""
        >
          <div v-html="video" v-show="show"></div>
        </b-modal>
      </div>
    </template>
  </PageCard>
</template>

<script>
import { mapState } from 'vuex'
import PageCard from '@/modules/core/components/layouts/PageCard'
import AnalyticsCard from '@/modules/core/components/layouts/AnalyticsCard'
import UseCaseDetails from '@/modules/core/pages/UseCaseDetails.vue'
import imgVcPC from '../../../static/img/vc-benchmarking.png'
import imgVcA from '../../../static/img/icon-benchmarking.png'
import imgVcVS from '../../../static/img/icon-strategic.png'
export default {
  name: 'AboutDashboard',
  components: {
    PageCard,
    AnalyticsCard,
    UseCaseDetails
  },
  data() {
    return {
      imgVcPC: imgVcPC,
      imgVcA: imgVcA,
      imgVcVS: imgVcVS,
      show: false,
      video: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1024643734?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="VC FI Prospecting + CRM"></iframe></div>`,
      videoPC: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1024643734?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="VC FI Prospecting + CRM"></iframe></div>`,
      videoA: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1024237670?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="VC FI Analytics"></iframe></div>`,
      videoVS: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1033751519?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="2024 FI Vertical Strategy - Active Presenter"></iframe></div>`
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  },
  methods: {
    showModal(code) {
      this.video =
        code === 'PC' ? this.videoPC : code === 'A' ? this.videoA : this.videoVS
      this.show = true
      this.$bvModal.show('videoModal')
    }
  }
}
</script>
<style lang="scss" src="../../modules/core/scss/infoCards.scss"></style>
