<template>
  <div :class="partnerClass">
    <ethos v-if="onEthosPartnerSite"></ethos>
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
// global
import nav from './_nav'
import router from '@/router'
import store from '@/store/store'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
// ui components
import Ethos from '@/partners/ethos/Ethos.vue'
import deepClone from '@/utilities.js'

export default {
  name: 'app',
  components: {
    Ethos
  },
  beforeCreate() {
    if (window.location.host === process.env.VUE_APP_ETHOS_URL_NAME) {
      store.commit('setPartnerSite', 'cu2')
      document.body.classList.add('partner-cu2')
    } else {
      store.commit('setPartnerSite', null)
      document.body.classList.remove('partner-cu2')
    }
  },
  created() {
    window.addEventListener('load', this.updateSidebarItems())
    window.addEventListener('load', this.updateChameleon())
  },
  mounted() {
    this.ro = new ResizeObserver(this.onResize)
    this.ro.observe(document.documentElement)
  },
  beforeDestroy() {
    this.ro.unobserve(document.documentElement)
    window.removeEventListener('load', this.updateSidebarItems())
    window.removeEventListener('load', this.updateChameleon())
  },
  data() {
    return {
      ro: null,
      subNav: nav.subNavItems,
      nestedNav: nav.nestedNavItems,
      advisorCUMenu: nav.advisorCUMenu,
      advisorBankMenu: nav.advisorBankMenu,
      appsAndRoutes: {
        dashboard: null,
        technographics: 'FI Prospecting & CRM',
        reporter: 'FIN Reporter',
        analytics: 'FI Analytics',
        reports: 'FI Vertical Strategy',
        strategy: 'Fintech Strategy',
        fin_profile: 'FIN Profile',
        advisor: 'FIN Advisor',
        report_writer: 'Report Writer',
        fin_query: 'FIN Query',
        industry_reporter: 'FIN Industry Reporter',
        vendor_fit: 'FIN VendorFit',
        market_reports: 'FinTech Market Reports',
        institution_analytics: 'Institution Analytics',
        technology_analytics: 'Technology Analytics',
        digital_channels: 'FI Vertical Strategy',
        digitalchannels: 'FI Vertical Strategy'
      }
    }
  },

  computed: {
    ...mapGetters(['isFinancialInstitution']),
    ...mapGetters('Ability', ['canAccessImportTools', 'showCrm']),
    ...mapGetters({
      isAdvisorCreditUnionSelected: 'Advisor/isSelectedCreditUnion',
      currentUserEmail: 'Authentication/currentUserEmail',
      keyMetrics: 'Advisor/keyMetrics',
      companyType: 'Authentication/companyType',
      isFiMenu: 'Authentication/isFiMenu'
    }),
    ...mapState('Ability', {
      enabledModules: state => state.enabledModules
    }),
    ...mapState({
      isSidebarExpanded: state => state.isSidebarExpanded,
      isSidebarAutoMinimized: state => state.isSidebarAutoMinimized
    }),
    homeNav() {
      return this.enabledModules.find(nav => nav.type === 'home')
    },
    partnerClass() {
      return this.onEthosPartnerSite ? 'partner-cu2' : ''
    },
    onEthosPartnerSite() {
      return window.location.host === process.env.VUE_APP_ETHOS_URL_NAME
    },
    isAdvisor() {
      return this.$route.meta.type == 'advisor'
    }
  },
  methods: {
    ...mapActions('Authentication', ['fetchUserGroups']),
    ...mapActions('ThirdPartyServices', ['setUserDataToChameleon']),
    ...mapMutations([
      'setSidebarApps',
      'setPartnerSite',
      'setSidebarExpanded',
      'setSidebarAutoMinimized'
    ]),
    ...mapMutations('Ability', ['setCurrentUserGuide']),

    onResize() {
      let width = document.documentElement.clientWidth
      if (width < 1270) {
        if (
          (this.isAdvisor || width < 768) &&
          !document.body.classList.contains('sidebar-minimized')
        ) {
          document.body.classList.add('sidebar-minimized')
          document.body.classList.add('brand-minimized')
          this.setSidebarAutoMinimized(true)
          this.setSidebarExpanded(false)
        }
      } else {
        if (!this.isSidebarExpanded) {
          document.body.classList.remove('sidebar-minimized')
          document.body.classList.remove('brand-minimized')
        }
        this.setSidebarAutoMinimized(false)
      }
    },

    updateSidebarItems() {
      let urlParts = this.$route.path.split('/')
      let firstLevelApp = this.appsAndRoutes[urlParts[1]]
      let secondLevelApp = this.appsAndRoutes[urlParts[2]]
      if (this.isFiMenu) {
        if (firstLevelApp === 'FI Prospecting & CRM')
          firstLevelApp = 'Institution Analytics'
        if (
          secondLevelApp === 'FinTech Market Reports' ||
          firstLevelApp === 'FI Vertical Strategy'
        )
          firstLevelApp = 'Technology Analytics'
      }

      let selectedApp =
        this.nestedNav.includes(firstLevelApp) ||
        this.nestedNav.includes(secondLevelApp)
          ? secondLevelApp
          : firstLevelApp

      let selectedNav = this.enabledModules.find(
        nav => nav.name === selectedApp
      )

      if (selectedNav)
        this.setCurrentUserGuide(
          selectedNav.user_guide_url == '' ? null : selectedNav.user_guide_url
        )
      let sidebarAppsList = []

      if (selectedApp === null || selectedApp === undefined) {
        sidebarAppsList = this.enabledModules
        this.setCurrentUserGuide(null)
      } else if (selectedNav) {
        sidebarAppsList = [this.homeNav, selectedNav]
        this.setCurrentUserGuide(
          selectedNav.user_guide_url == '' ? null : selectedNav.user_guide_url
        )
        if (
          ['Technology Analytics', 'FI Vertical Strategy'].includes(
            selectedNav.name
          )
        ) {
          let name =
            urlParts[2] === 'market_reports'
              ? 'FinTech Market Reports'
              : urlParts[2] === 'dashboard'
              ? 'FIN Digital Channels'
              : urlParts[2] === 'industry_reporter'
              ? 'FIN Industry Reporter'
              : null
          let child = selectedNav.children.find(nav => nav.name === name)
          if (child)
            this.setCurrentUserGuide(
              child.user_guide_url == '' ? null : child.user_guide_url
            )
        } else if (urlParts[1] === 'technographics') {
          let child = selectedNav.children.find(
            nav => nav.name === 'FIN Technographics'
          )
          if (child)
            this.setCurrentUserGuide(
              child.user_guide_url == '' ? null : child.user_guide_url
            )
        }
      } else {
        this.enabledModules.forEach(mod => {
          let selectedChild = mod.children.find(nav => nav.name === selectedApp)
          if (selectedChild)
            this.setCurrentUserGuide(
              selectedChild.user_guide_url == ''
                ? null
                : selectedChild.user_guide_url
            )
        })
        let secondLevelApp = deepClone(
          this.subNav.find(snav => snav.name === selectedApp)
        )
        if (secondLevelApp) {
          sidebarAppsList = [this.homeNav, secondLevelApp]
        } else {
          router.push('/dashboard')
          sidebarAppsList = this.enabledModules
        }

        if (selectedApp === 'FIN Advisor') {
          let growthMenu = secondLevelApp.children.find(
            menu => menu.name === 'Growth'
          )
          growthMenu.children = this.isAdvisorCreditUnionSelected
            ? this.advisorCUMenu
            : this.advisorBankMenu
          let keyMetricsMenu = secondLevelApp.children.find(
            menu => menu.name === 'Key Metrics'
          )
          if (
            urlParts[3] &&
            !['select_institution', 'select_peer_group'].includes(urlParts[3])
          ) {
            keyMetricsMenu.children = this.keyMetrics
          } else {
            keyMetricsMenu.children = []
          }
        }
        if (selectedApp === 'FIN Query' && !this.canAccessImportTools) {
          secondLevelApp.children = secondLevelApp.children.filter(
            menu => menu.name != 'Conference Import'
          )
        }
        if (selectedApp === 'FIN Query' && !this.showCrm) {
          let child = secondLevelApp.children.find(
            menu => menu.name == 'FIN CRM'
          )
          child.class = 'sub-nav-child pl-4 disabled-sub-nav'
        }
      }
      this.setSidebarApps(sidebarAppsList)
    },
    updateChameleon() {
      if (this.currentUserEmail) {
        this.fetchUserGroups().then(() => {
          this.setUserDataToChameleon()
        })
      }
    }
  },
  watch: {
    $route: function() {
      this.updateSidebarItems()
    }
  }
}
</script>

<style>
@import '~flag-icon-css/css/flag-icon.min.css';
@import '~font-awesome/css/font-awesome.min.css';
@import '~simple-line-icons/css/simple-line-icons.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';

@import '../static/css/coreui-icons-linear.min.css';
@import '../static/css/coreui-icons-solid.min.css';
</style>
<style lang="scss">
@import '../scss/style';
</style>
