<template>
  <div>
    <InstitutionsDashboard v-if="isFiMenu"></InstitutionsDashboard>
    <VendorsAdvisorsDashboard v-else></VendorsAdvisorsDashboard>
  </div>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
// UI components
import InstitutionsDashboard from './InstitutionsDashboard'
import VendorsAdvisorsDashboard from './VendorsAdvisorsDashboard'

export default {
  name: 'AboutDashboard',
  components: {
    InstitutionsDashboard,
    VendorsAdvisorsDashboard
  },
  computed: {
    ...mapGetters('Authentication', ['isFiMenu']),
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FI Navigator'
    }
  }
}
</script>
