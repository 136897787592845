export default {
  totalPriceInDollars(state) {
    if (state.totalPrice === null) {
      return null
    }
    return parseFloat(state.totalPrice)
      .toFixed(2)
      .split('.')[0]
  },
  totalPriceInCents(state) {
    if (state.totalPrice === null) {
      return null
    }
    return parseFloat(state.totalPrice)
      .toFixed(2)
      .split('.')[1]
  },
  isProcessingData(state) {
    return state.fetchingFIs || state.fetchingPriceData
  },
  queryElements(state) {
    return state.queryElements
  },
  selectedQuery(state) {
    return state.selectedQuery.query
  },
  queryRules(state) {
    return state.queryRules
  },
  currentQuery(state) {
    return state.currentQuery
  },
  isCsvDownloadAllowed(state) {
    return state.downloadableFormats.includes('csv')
  },
  isXlsxDownloadAllowed(state) {
    return state.downloadableFormats.includes('xlsx')
  },
  ruleID(state) {
    return option => {
      let rule =
        state.queryRules && state.queryRules.find(rule => rule.label === option)
      return rule && rule.id
    }
  },
  baseQueryOptions(state, getters, rootState, rootGetters) {
    let customSearchOptions = [
      { value: getters.ruleID('Job Search'), text: '  Job Search' },
      { value: getters.ruleID('Site Search'), text: '  Site Search' }
    ]
    if (
      rootGetters['Ability/showFinQuery'] &&
      rootGetters['Ability/isFIContactsAvailable']
    ) {
      customSearchOptions.push({
        value: getters.ruleID('Contact Search'),
        text: '  Contact Search'
      })
    }
    let options = [
      // { value: null, text: 'Please Select', disabled: true },
      {
        value: getters.ruleID('Select Institutions'),
        text: 'Select Institutions'
      },
      { value: getters.ruleID('Firmographics'), text: 'Firmographics' },
      { value: getters.ruleID('Market'), text: 'Market Area' },
      { value: getters.ruleID('Segmentation'), text: 'Segmentation' },
      { value: getters.ruleID('Technographics'), text: 'Technographics' },

      {
        text: 'Financial Data',
        $isDisabled: true
      },
      {
        value: getters.ruleID('Performographics'),
        text: '  Performographics'
      },
      {
        value: getters.ruleID('Common Metrics (Bank/CU)'),
        text: '  Common Metrics (Bank/CU)'
      },
      {
        value: getters.ruleID('Detailed Metrics'),
        text: '  Detailed Metrics'
      },
      {
        value: getters.ruleID('Common Statements (Bank/CU)'),
        text: '  Common Statements (Bank/CU)'
      },
      {
        value: getters.ruleID('Detailed Statements'),
        text: '  Detailed Statements'
      },
      {
        text: 'Custom Search',
        $isDisabled: true
      },
      ...customSearchOptions
    ]

    //console.log(rootState.Ability.canPurchase)
    if (rootGetters['Ability/showDigitalChannels']) {
      options.splice(5, 0, {
        value: getters.ruleID('Digital Channels'),
        text: 'Digital Channels'
      })
    }
    if (rootGetters['Ability/showVendorFit']) {
      options.splice(16, 0, {
        text: 'VendorFit',
        $isDisabled: true
      })
      options.splice(17, 0, {
        value: getters.ruleID('Core Processing'),
        text: '  Core Processing'
      })
    }
    return options
  },
  baseQueryOptionsForMBC(state, getters, rootState, rootGetters) {
    let customSearchOptions = [
      { value: getters.ruleID('Job Search'), text: '  Job Search' },
      { value: getters.ruleID('Site Search'), text: '  Site Search' }
    ]
    if (
      rootGetters['Ability/showFinQuery'] &&
      rootGetters['Ability/isFIContactsAvailable']
    ) {
      customSearchOptions.push({
        value: getters.ruleID('Contact Search'),
        text: '  Contact Search'
      })
    }
    let options = [
      // { value: null, text: 'Please Select', disabled: true },
      {
        value: getters.ruleID('Select Institutions'),
        text: 'Select Institutions'
      },
      { value: getters.ruleID('Firmographics'), text: 'Firmographics' },
      { value: getters.ruleID('Technographics'), text: 'Technographics' },
      { value: getters.ruleID('Mortgage Lending'), text: 'Mortgage Lending' },

      {
        text: 'Custom Search',
        $isDisabled: true
        //options: customSearchOptions
      },
      ...customSearchOptions
    ]
    return options
  },
  technographicRules(state) {
    return state.queryRules.filter(qr => {
      return [
        'Retail Services',
        'Business Services',
        'Operating Solutions'
      ].includes(qr.label)
    })
  },
  isMortgageLender(state, getters, rootState, rootGetters) {
    return (
      rootGetters['Ability/isFinQueryAvailable'] &&
      rootGetters['Ability/isMbcAvailable'] &&
      state.isMortgageLender
    )
  },
  getfirmographicMBCOptions(state) {
    let rule = state.queryRules.find(qr => qr.label === 'Firmographics')
    let options = []

    rule.children.forEach(element => {
      var at = []
      if (element.operators.action_taken) {
        element.operators.action_taken.forEach(child => {
          at.push({
            name: child.label,
            property: child.value,
            fi_type: 'all',
            type: 'mbc',
            description: ''
          })
        })
      }
      var ot = []
      if (element.operators.output_type) {
        element.operators.output_type.forEach(child => {
          ot.push({
            name: child.label,
            property: child.value,
            fi_type: 'all',
            type: 'mbc',
            description: ''
          })
        })
      }
      options.push({
        name: element.label,
        property: element.element_property,
        fi_type: 'all',
        type: 'mbc',
        description: '',
        children: [...at, ...ot]
      })
    })
    return options
  }
  // isDigitalChannelsAvailable(state) {
  //   return (
  //     state.enterprisePermissions &&
  //     state.enterprisePermissions.digital_channel_enterprise
  //   )
  // },
  // isFIContactsAvailable(state) {
  //   return (
  //     state.enterprisePermissions &&
  //     state.enterprisePermissions.contact_details_enterprise
  //   )
  // },
  // isFinQueryAvailable(state) {
  //   return (
  //     state.enterprisePermissions &&
  //     state.enterprisePermissions.prospect_list_enterprise
  //   )
  // }
}
